
export default {
    name: 'LangPage',
    data() {
        return {
            lang: {},
            identify: '',
            list: []
        }
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.lang = this.$storage.get('language')
        this.identify = this.lang.identify
        this.getLanguageAjax()
    },
    methods: {
        getLanguageAjax() {
            this.$api.common.getLang().then(response => {
                const res = response.result || [];
                this.list = res
            })
        },
        handleRadio(obj) {
            this.identify = obj.identify
            this.$storage.set('language', obj, true)
            this.$router.back()
        },
        // SEO HEAD
        setHead() {
            const head = this.$ctxApi.seoHead.initial({
                title: this.$translate('Language'),
                keywords: this.$translate('Language'),
                description: this.$translate('Language'),
                image: 'https://cdn2.selleroa.com/yfn-upload/review/1694078645122.png'
            });
            return {
                ...head
            }
        },
    },
}
