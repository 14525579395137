
import md5 from '/utils/md5';
import Validate from '/utils/validate'

const validate = new Validate()

export default {
    name: 'ChangePwd',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            skeleton: {
                visible: false,
            },
            form: {
                oldPwd: '',               // 旧密码
                newPwd: '',               // 新密码
                tryPwd: '',               // 二次密码
                hasCheckOldPwd: false,    // 旧密码校验
                hasTipOldPwd: false,      // 旧密码提示
                hasCheckNewPwd: false,    // 新密码校验
                hasTipNewPwd: false,      // 新密码提示
                hasCheckTryPwd: false,    // 二次密码校验
                hasTipTryPwd: false,      // 二次密码提示
                hasSamePwd: false,        // 新/二次密码是否相同
                hasAllInput: false,       // 是否均输入
                hasCheck: false,          // 校验是否成功
            },
            see: {
                oldPwdStatus: false,
                newPwdStatus: false,
                tryPwdStatus: false,
            },
            params: {
                email: '',                // 邮件
                inputType: 1,             // 当前操作的输入框
                send: {}
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
    },
    methods: {
        // 修改密码接口
        setChangePwdAjax() {
            this.$api.login.setResetPwd({
                email: this.params.email,
                pwd: md5(this.form.newPwd),
                oldPwd: md5(this.form.oldPwd),
                rePwd: md5(this.form.tryPwd)
            }).then(response => {
                const res = response.result || {};
                !res && (res = {})
                if(!res.effective) {
                    this.$Toast(response.error_msg);
                    return
                }
                this.$Toast(`${this.$translate('Password has been successfully changed')}`);
                this.$fnApi.debounce(() => {
                    this.$router.back()
                }, 2000)
            })
        },
        // 清空密码
        handleClear(obj) {
            if(obj == 1) {
                this.form.newPwd = ''
            } else if(obj == 2){
                this.form.tryPwd = ''
            } else {
                this.form.oldPwd = ''
            }
            this.inputPwd(obj)
        },
        // 密码查看
        handleSee(obj) {
            if(obj == 1) {
                this.see.newPwdStatus = !this.see.newPwdStatus
            } else if(obj == 2){
                this.see.tryPwdStatus = !this.see.tryPwdStatus
            } else{
                this.see.oldPwdStatus = !this.see.oldPwdStatus
            }
        },
        // input
        inputPwd(obj) {
            this.params.inputType = obj
            this.form.hasAllInput = this.form.newPwd && this.form.tryPwd && this.form.oldPwd
            !this.form.newPwd && (this.form.hasTipNewPwd = false)
            !this.form.tryPwd && (this.form.hasTipTryPwd = false)
            !this.form.oldPwd && (this.form.hasTipOldPwd = false)
        },
        // 提交
        handleSubmit() { // 提交按钮
            this.checkout()
            if(!this.form.hasCheck) {
                return
            }
            this.setChangePwdAjax()
        },
        checkout() { // 校验
            this.form.hasCheckNewPwd = validate.checkPwd(this.form.newPwd)
            this.form.hasCheckTryPwd = validate.checkPwd(this.form.tryPwd)
            this.form.hasCheckOldPwd = validate.checkPwd(this.form.oldPwd)
            this.form.hasSamePwd = (this.form.newPwd == this.form.tryPwd)
            this.form.hasTipNewPwd = !this.form.hasCheckNewPwd
            this.form.hasTipTryPwd = !this.form.hasCheckTryPwd
            this.form.hasTipOldPwd = !this.form.hasCheckOldPwd
            this.form.hasCheck = this.form.hasCheckNewPwd && this.form.hasCheckTryPwd && this.form.hasCheckOldPwd
            if(!this.form.hasSamePwd && this.form.hasCheck) {
                this.form.hasCheck = false
                this.$Toast(`${this.$translate(`New password and confirm password do not match`)}.`)
            }
        },
        // 初始化
        initialSet() {
            const query = this.$route.query;
            const email = this.$storage.get('email');
            this.params.email = query.email || email || ''
        }
    },
}
