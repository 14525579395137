
import md5 from '/utils/md5';
import Validate from '/utils/validate'

const validate = new Validate()
export default {
    name: 'ResetPwd',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            skeleton: {
                visible: false,
            },
            tip: {                       // 提示
                hasPwdTip: false,
                list: []
            },
            see: {
                newPwdStatus: false,
                tryPwdStatus: false,
            },
            clear: '',                    // 节流
            hasForm: true,                // 是否提交表单
            hasSuccess: false,            // 邮件是否发送成功
            tips: {
                title: '',
                message: ''
            },
            form: {
                newPwd: '',               // 新密码
                tryPwd: '',               // 二次密码
                hasCheckNewPwd: false,    // 新密码校验
                hasCheckNewPwdNum: false, // 新密码是否包含数字
                hasCheckNewPwdEn: false,  // 新密码是否包含字母
                hasTipNewPwd: false,      // 新密码提示
                hasCheckTryPwd: false,    // 二次密码校验
                hasTipTryPwd: false,      // 二次密码提示
                hasSamePwd: false,        // 密码是否相同
                hasAllInput: false,       // 是否均输入
                hasCheck: false,          // 校验是否成功
            },
            params: {
                email: '',                // 邮件
                inputType: 1,             // 当前操作的输入框
                verificationCode: '',     // 验证码
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
    },
    methods: {
        // 密码校验
        setCheckPwdAjax() {
            this.$api.login.setResetCheckPwd({
                email: this.params.email,
                pwd: md5(this.form.newPwd),
                rePwd: md5(this.form.tryPwd),
                verificationCode: this.params.verificationCode
            }).then(response => {
                const res = response.result || {};
                if(res.effective) {
                    this.setResetPwdAjax()
                    this.tips.title = `${this.$translate('Reset Successfully')}`
                } else {
                    this.hasForm = false
                    this.hasSuccess = false
                    this.tips.title = res.title
                    this.tips.message = res.message
                }
            })
        },
        // 重置密码接口
        setResetPwdAjax() {
            this.$api.login.setResetPwd({
                email: this.params.email,
                pwd: md5(this.form.newPwd),
                rePwd: md5(this.form.tryPwd),
                verificationCode: this.params.verificationCode
            }).then(response => {
                const res = response.result || {};
                this.hasForm = false
                this.hasSuccess = true
            })
        },
        // 清空密码
        handleClear(obj) {
            if(obj == 1) {
                this.form.newPwd = ''
            } else {
                this.form.tryPwd = ''
            }
            this.inputPwd(obj)
        },
        // 密码查看
        handleSee(obj) {
            if(obj == 1) {
                this.see.newPwdStatus = !this.see.newPwdStatus
            } else {
                this.see.tryPwdStatus = !this.see.tryPwdStatus
            }
        },
        // input
        inputPwd(obj) {
            this.params.inputType = obj
            this.form.hasAllInput = this.form.newPwd && this.form.tryPwd
            !this.form.newPwd && (this.form.hasTipNewPwd = false)
            !this.form.tryPwd && (this.form.hasTipTryPwd = false)
            this.tip.hasPwdTip = this.form.newPwd
            // 新密码提示
            this.checkout()
            if(this.params.inputType == 1) {
                let statusArr = [this.form.hasCheckNewPwd, this.form.hasCheckNewPwdEn, this.form.hasCheckNewPwdNum];
                this.tip.list.forEach((item, i) => {
                    this.$set(this.tip.list, i, {...item, status: statusArr[i]})
                })
            }
        },
        // 操作
        handleBtn(obj) {
            if(obj == 1) {
                this.$router.push({
                    name: 'ForgotPwd'
                })
            } else if(obj == 2) {
                this.$router.push({
                    name: 'Login'
                })
            } else {
                this.$router.push({
                    name: 'Home'
                })
            }
        },
        // 提交
        handleSubmit() { // 提交按钮
            this.checkout()
            if(!this.form.hasSamePwd) {
                this.$Toast(`${this.$translate(`New password and confirm password do not match`)}.`)
            }
            if(!this.form.hasCheck) {
                return
            }
            // 密码校验
            this.clear && clearTimeout(this.clear)
            this.clear = setTimeout(() => {
                this.setCheckPwdAjax()
            }, 200)
        },
        checkout() { // 校验
            this.form.hasCheckNewPwd = validate.checkPwd(this.form.newPwd)
            this.form.hasCheckTryPwd = validate.checkPwd(this.form.tryPwd)
            this.form.hasTipNewPwd = !this.form.hasCheckNewPwd
            this.form.hasSamePwd = (this.form.newPwd == this.form.tryPwd)
            this.form.hasTipTryPwd = !this.form.hasCheckTryPwd
            if(!this.form.hasSamePwd) {
                this.form.hasTipTryPwd = true
            }
            // 新密码校验
            this.form.hasCheckNewPwdNum = /\d/.test(this.form.newPwd);
            this.form.hasCheckNewPwdEn =  /[a-zA-Z]/.test(this.form.newPwd);
            this.form.hasCheck = this.form.hasCheckNewPwd && this.form.hasCheckTryPwd && this.form.hasSamePwd
        },
        // 初始化
        initialSet() {
            const query = this.$route.query;
            this.params.email = query.email
            this.params.verificationCode = query.verificationCode
            this.tip.list = [{
                text: this.$translate('8 to 16 characters'),
                status: false,
                type: 1
            }, {
                text: this.$translate('At least one letter'),
                status: false,
                type: 2
            }, {
                text: this.$translate('At least one number'),
                status: false,
                type: 3
            }]
        }
    },
}
