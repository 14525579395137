
import Validate from '/utils/validate'

const validate = new Validate()

export default {
    name: 'ForgotPwd',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        hasReceiveClose: false
    },
    data() {
        return {
            loading: false,
            skeleton: {
                visible: false,
            },
            form: {
                email: '',
                hasEmailOption: false,
                hasEmail: false,             // email是否校验通过
                hasEmailTip: false,          // email提示是否显示
                hasCheck: false,             // 所有条件是否校验通过
                submitType: 1,               // 提交类型 1 发送邮件
            },
            params: {
                hasSuccess: false,           // 是否发送传功
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        // 发送邮件接口
        setSendEmailAjax() {
            this.$api.login.setResetLinkPwd({
                email: this.form.email,
            }).then(response => {
                this.loading = false
                if(response.error_code == 103 || response.code != 'success') {
                    return
                }
                const res = response.result || {};
                this.params.hasSuccess = true
            })
        },
        // 关闭
        handleClose() {
            if(this.hasReceiveClose || this.$route.name=='Login') {
                this.$emit('closePwd')
            } else {
                this.$router.back()
            }
        },
        // 重试
        handleTry() {
            this.params.hasSuccess = false
        },
        // 跳转
        jumpLogin() {
            this.$route.name=='Login' ? this.$emit('closePwd')
                : this.$router.push({
                name: 'Login'
            })
        },
        // 提交
        handleBtn(obj) { // 提交按钮
            this.form.submitType = obj
            if(!this.form.email) {
                return
            }
            this.loading = true
            this.checkForm()
            this.$fnApi.debounce(() => {
                if(this.form.hasCheck) {
                    this.setSendEmailAjax()
                }
            }, 50)
        },
        checkForm() { // 校验
            // 邮箱校验
            this.form.hasEmail = validate.userName(this.form.email)
            this.form.hasEmailTip = !this.form.hasEmail
            // 是否校验通过
            this.form.hasCheck = this.form.hasEmail
        },
    },
}
